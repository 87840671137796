import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { graphql, Link } from "gatsby";
import styled from "@emotion/styled";
import dimensions from "styles/dimensions";
import colors from "styles/colors";
import Layout from "components/Layout";
import PostListItem from "components/PostListItem";
import LinkListItem from "components/LinkListItem";
import Newsletter from "components/newsletter";



const WorkAction = styled(Link)`
    font-weight: 600;
    text-decoration: none;
    color: currentColor;
    transition: all 150ms ease-in-out;
    margin-left: auto;

    @media(max-width:${dimensions.maxwidthTablet}px) {
       margin: 0 auto;
    }

    span {
        margin-left: 1em;
        transform: translateX(-8px);
        display: inline-block;
        transition: transform 400ms ease-in-out;
    }

    &:hover {
        color: ${colors.blue500};
        transition: all 150ms ease-in-out;

        span {
            transform: translateX(0px);
            opacity: 1;
            transition: transform 150ms ease-in-out;
        }
    }
`

const BlogTitle = styled("h1")`
    margin-bottom: 1em;
`



const Articles = ({ posts, meta, links, tag }) => {

  function just_tags(arr) {
    var tag_list = [];
    arr.forEach((item, i) => {
      if (typeof(item.node._meta.tags)!=='undefined') {
        item.node._meta.tags.forEach((tag, i) => {
          if (!tag_list.includes(tag)) {
            tag_list.push(tag);
          }

        },tag_list);

      }
    },tag_list);
    console.log(tag_list);
    return tag_list;
  }


  links=links.filter(item => item['node']['link_author']==='Koby Ofek');
  var posts_and_links=posts.concat(links);
  posts_and_links=posts_and_links.sort((a, b) => (a['node']['post_date'] > b['node']['post_date']) ? -1 : 1)
  var tags = just_tags(posts_and_links);
  debugger;

  var description = 'Articles tagged '+tag+' in Koby Ofek\'s digital garden';

  return(
    <>
    <Helmet>
        <title>Articles By Koby Ofek about {tag}</title>
        <meta name="description" content={description} />
        <meta name="og:title" content={'Articles By Koby Ofek about '+tag} />
        <meta name="og:description" content={description} />
        <meta name="og:type" content='website' />
        <meta name="twitter:card" content='summary' />
        <meta name="twitter:creator" content='@kobyof' />
        <meta name="twitter:title" content={'Articles By Koby Ofek about '+tag} />
        <meta name="twitter:description" content={description} />
    </Helmet>

        <Layout>
            <BlogTitle>
                Articles about {tag}
            </BlogTitle>
            {posts_and_links.length>5 && <WorkAction to={"/articles"}>
                See all articles <span>&#8594;</span>
            </WorkAction>}


                {posts_and_links.map((postOrLink, i) => {

                  if (typeof(postOrLink.node.link)!=='undefined') {
                    // this means it's a link

                    return (
                      <LinkListItem
                        source='articles'
                        title={postOrLink.node.link_title}
                        uid={postOrLink.node._meta.uid}
                        tags={postOrLink.node._meta.tags}
                        alltags={tags}
                        link={postOrLink.node.link.url}
                        description={postOrLink.node.post_preview_description}
                        post_date={postOrLink.node.post_date}
                        key={i}
                      />
                    )
                } else {

                  return (
                  <PostListItem
                    source='articles'
                    title={postOrLink.node.post_title}
                    uid={postOrLink.node._meta.uid}
                    tags={postOrLink.node._meta.tags}
                    alltags={tags}
                    description={postOrLink.node.post_preview_description}
                    post_date={postOrLink.node.post_date}
                    key={i}
                  />
                )



                  }

                })}
                <WorkAction to={"/articles"}>
                    See all articles <span>&#8594;</span>
                </WorkAction>
                <br /><br />
                <Newsletter />
        </Layout>
    </>
);
}

export default ({ data, classes, pageContext }) => {
  debugger;
    const posts = data.prismic.allPosts.edges;
    const meta = data.site.siteMetadata;
    const links = data.prismic.allLinks.edges;
    if (!posts) return null;

    return (
        <Articles posts={posts} meta={meta} links={links} tag={pageContext.uid} />
    )
}

Articles.propTypes = {
    posts: PropTypes.array.isRequired,
    meta: PropTypes.object.isRequired,
    links: PropTypes.array.isRequired
};


export const query = graphql`
query tagQuery($uid: [String!])  {
      prismic {
        allLinks(tags_in: $uid) {
            edges {
                node {
                  link {
                      ... on PRISMIC__ExternalLink {
                          _linkType
                          url
                      }
                   }
                   link_author
                   link_category
                   link_title
                   post_preview_description
                   post_date
                   _meta {
                     tags
                     uid
                   }
                }
            }
        }
          allPosts(tags_in: $uid) {
            pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
              edges {
                  node {
                      post_title
                      post_date
                      post_category
                      post_preview_description
                      post_author
                      _meta {
                          uid
                          tags
                      }
                  }
              }
          }
      }
      site {
          siteMetadata {
              title
              description
              author
          }
      }
  }
`
