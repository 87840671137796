import React from "react";
import { Link } from "gatsby";
import { RichText } from "prismic-reactjs";
import styled from "@emotion/styled";
import colors from "styles/colors";
import PropTypes from "prop-types";

const PostListItemContainer = styled('div')`
    /* border: 1px solid ${colors.grey200};
    padding: 3em 2.5em 2.25em 2.5em;
    border-radius: 3px; */
    padding: 0.5em 0.5em 0.5em 0em;;
    text-decoration: none;
    color: currentColor;
    display: flex;
    flex-direction: column;
    /* box-shadow: 0px 9px 24px rgba(0, 0, 0, 0.06); */
    /* transition: all 150ms ease-in-out; */

    &:hover {
        /* box-shadow: 0px 9px 24px rgba(0, 0, 0, 0.1); */
        /* transition: all 150ms ease-in-out; */
        cursor: pointer;
        background-color: ${colors.grey100}


    }
`

const PostTitle = styled(Link)`
    color: currentColor;
    margin: 0;
    text-decoration: none;
    margin-top: 0.5em;
    font-weight:300;
    &.articles {
      margin-bottom: 0.4rem;
    font-size: 1.9em;
    line-height: 1.1;
    font-weight:700;
    }
    &.homepage {
      margin-bottom: 0.4rem;
    font-size: 1.9em;
    line-height: 1.1;
    font-weight:700;
    }
`
const PostTags = styled("div")`
    margin: 0;
    margin-top: 0.25em;
    font-size:0.95em;
    font-weight:700;
`
const PostDate = styled("div")`
float:right;
font-weight:100
`

const PostTag = styled(Link)`
&.tag0 { color: ${colors.blue600};    background-color: ${colors.blue200};}
&.tag1 { color: ${colors.orange600};  background-color: ${colors.orange200};}
&.tag2 { color: ${colors.purple600};  background-color: ${colors.purple200};}
&.tag3 { color: ${colors.green600};   background-color: ${colors.green200};}
&.tag4 { color: ${colors.teal600};    background-color: ${colors.teal200};}
margin-right:0.5em;
padding:0.1em;
text-decoration:none;

`



const PostListItem = ({  title, uid, tags, alltags, source, description,post_date}) => {

  return(
    <PostListItemContainer className="BlogPostListItem">
        <PostTitle className={source} to={`/articles/${uid}`}>
            {title[0].text}
        </PostTitle>
        <PostTags>
            {tags.map((tag, i) => {

              var newClassName='tag'+alltags.indexOf(tag)%5;

            return (

            <PostTag to={`/tag/${tag}`} className={newClassName}key={i}>{tag}</PostTag>
          )},alltags)}
          {post_date && <PostDate>{post_date}</PostDate>}
        </PostTags>
        {description && <>{RichText.render(description)}</>}
    </PostListItemContainer>
)}

export default PostListItem;

PostListItem.propTypes = {
    title: PropTypes.array.isRequired,
    uid: PropTypes.string.isRequired,
    tags: PropTypes.array.isRequired,
    source: PropTypes.string.isRequired,
}
